const intakeInfo = {
  companyName: "Template",
  companyAddress: "Template Street",
  domainName: "www.template.com",
  contactEmail: "",

  siteName: "Template site",
  highlightColor: "#ff00ff",

  taxNumber: "1313",
};

export default intakeInfo;
